'use client'

import googleAnalytics from '@analytics/google-analytics'
import googleTagManager from '@analytics/google-tag-manager'
import intercomPlugin from '@analytics/intercom'
import { useAuth } from '@clerk/nextjs'
import WebVitals from '@UI/sharedComponents/WebVitals'
import Analytics from 'analytics'
import facebookPixelPlugin from 'lib/analytics/facebook-pixel-plugin'
import postHog from 'lib/analytics/posthog-analytics-plugin'
import { Router } from 'next/router'
import React, { useEffect } from 'react'
import { AnalyticsProvider } from 'use-analytics'

import LivAwsRumProvider from './_components/LivAwsRumProvider'

const analytics = Analytics({
  app: 'LivPlace',
  debug: process.env.NODE_ENV === 'development',
  plugins: [
    googleAnalytics({
      measurementIds: [
        process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID ?? 'G-J2C1WW1SKQ',
      ],
    }),
    googleTagManager({
      containerId: process.env.NEXT_PUBLIC_GTM_ID ?? 'GTM-WXKB9D2',
    }),
    facebookPixelPlugin({
      pixelId: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID ?? '935791191439007',
    }),
    intercomPlugin({
      appId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID ?? 'zbhz2gs6',
    }),
    postHog({
      token: process.env.NEXT_PUBLIC_POSTHOG_KEY ?? '',
      enabled: true,
      options: {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST ?? '',
        session_recording: {
          maskAllInputs: false,
          maskInputOptions: {
            password: true, // Highly recommended as a minimum!!
          },
        },
      },
    }),
  ],
})

export default function LivAnalyticsProvider({
  children,
}: Readonly<{
  children: React.ReactNode
}>) {
  const { isLoaded, actor } = useAuth()

  useEffect(() => {
    if (!isLoaded) return
    if (actor !== null) {
      // User is impersonating, disable all analytics
      analytics.plugins.disable(
        [
          'google-analytics',
          'google-tag-manager',
          'facebook-ads',
          'intercom',
          'posthog',
        ],
        () => {},
      )
    } else {
      // User is not impersonating, enable all analytics
      analytics.plugins
        .enable([
          'google-analytics',
          'google-tag-manager',
          'facebook-ads',
          'intercom',
          'posthog',
        ])
        .then(() => {
          /* Track initial page view */
          if (typeof window !== 'undefined') {
            analytics.page()
          }
          Router.events.on('routeChangeComplete', () => {
            analytics.page()
          })
        })
    }
  }, [actor, isLoaded])

  if (typeof window === 'undefined') {
    return (
      <>
        <WebVitals />
        <AnalyticsProvider instance={analytics}>{children}</AnalyticsProvider>
      </>
    )
  }

  return (
    <>
      <WebVitals />
      <LivAwsRumProvider>
        <AnalyticsProvider instance={analytics}>{children}</AnalyticsProvider>
      </LivAwsRumProvider>
    </>
  )
}
