const fbStandardEvents = [
  'AddPaymentInfo',
  'AddToCart',
  'AddToWishlist',
  'CompleteRegistration',
  'Contact',
  'CustomizeProduct',
  'Donate',
  'FindLocation',
  'InitiateCheckout',
  'Lead',
  'Purchase',
  'Schedule',
  'Search',
  'StartTrial',
  'SubmitApplication',
  'Subscribe',
  'ViewContent',
]

/* @ts-expect-error Aldo's too noob to work around this */
let fb
let fbLoaded = false
export default function facebookPixelPlugin(userConfig = {}) {
  return {
    name: 'facebook-ads',
    config: {
      ...userConfig,
    },
    /* @ts-expect-error Aldo's too noob to work around this */
    initialize: async ({ config }) => {
      if (typeof window !== 'undefined') {
        const { pixelId } = config
        await import('react-facebook-pixel')
          .then((module) => {
            fb = module.default
          })
          .then(() => {
            if (!fbLoaded) {
              /* @ts-expect-error Aldo's too noob to work around this */
              fb.init(pixelId, {
                autoConfig: true,
                debug: true,
              })
              fbLoaded = true
            }
          })
      }
    },
    /* @ts-expect-error Aldo's too noob to work around this */
    page: ({ _payload }) => {
      /* @ts-expect-error Aldo's too noob to work around this */
      fb.pageView()
    },
    /* @ts-expect-error Aldo's too noob to work around this */
    track: ({ payload }) => {
      if (!fbStandardEvents.includes(payload.event)) {
        /* @ts-expect-error Aldo's too noob to work around this */
        fb.trackCustom(payload.event, payload.properties)
      } else {
        /* @ts-expect-error Aldo's too noob to work around this */
        fb.track(payload.event, payload.properties)
      }
    },
    /* @ts-expect-error Aldo's too noob to work around this */
    identify: ({ _payload }) => {
      // I believe FB doesn't have an identify API any more
    },
    loaded: () => {
      return fbLoaded
    },
  }
}
