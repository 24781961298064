'use client'

import { AwsRumProvider } from 'aws-rum-react'
import React from 'react'

export default function LivAwsRumProvider({
  children,
}: Readonly<{
  children: React.ReactNode
}>) {
  if (!window) {
    return null
  }

  return (
    <AwsRumProvider
      sessionSampleRate={1}
      endpoint="https://dataplane.rum.us-east-1.amazonaws.com"
      telemetries={[
        'errors',
        'performance',
        [
          'http',
          {
            addXRayTraceIdHeader: true,
            recordAllRequests: false,
            urlsToExclude: [
              /posthog\.com/,
              /intercom\.io/,
              /google-analytics/,
              /googleapis/,
              /gstatic/,
              /facebook\.com/,
              /facebook\.net/,
              /cognito-identity\.us-east-1\.amazonaws\.com/,
              /dataplane\.rum\.us-east-1\.amazonaws\.com/,
              /sts\.us-east-1\.amazonaws\.com/,
              /accounts\.liv\.place/, // exclude clerk requests, otherwise they will fail due to CORS
              /clerk/, // exclude clerk requests, otherwise they will fail due to CORS
            ],
          },
        ],
      ]}
      recordResourceUrl={false}
      sessionEventLimit={0}
      allowCookies
      enableXRay
      version="1.0.0"
      /* @ts-expect-error - region is comming from the environment */
      region={process.env.NEXT_PUBLIC_AWS_REGION!}
      identityPoolId={process.env.NEXT_PUBLIC_AWS_RUM_IDENTITY_POOL_ID}
      guestRoleArn={process.env.NEXT_PUBLIC_AWS_RUM_GUEST_ROLE_ARN}
      id={process.env.NEXT_PUBLIC_AWS_RUM_APPLICATION_ID!}
      disableAutoPageView={false}
    >
      {children}
    </AwsRumProvider>
  )
}
