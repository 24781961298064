'use client'

import getQueryClient from '@lib/getQueryClient'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useMemo } from 'react'

export default function LivQueryProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const client = useMemo(() => getQueryClient(), [])

  return (
    <QueryClientProvider client={client}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
    </QueryClientProvider>
  )
}
