import { QueryClient } from '@tanstack/react-query'

type QueryError = Error & { response?: { status?: number } }

const REFRESH_INTERVAL = 2.5 * 60 * 1000

const getQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
        retry: (failureCount, error: QueryError) => {
          // Don't retry on 404
          if (error.response?.status === 404) {
            return false
          }
          // Retry up to 10 times by default
          return failureCount <= 10
        },
        refetchOnWindowFocus: false, // default: true
        staleTime: REFRESH_INTERVAL,
      },
    },
  })
}

export default getQueryClient
