/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * liv-backend
 * OpenAPI spec version: 1.0
 */
import type {
  GetCheckoutSessionV1StripeCheckoutSessionSessionIdGet200,
  LivStripeInvestigationCheckoutRequest,
  LivStripeRentOperationCheckoutRequest
} from '../livBackend.schemas'
import { customInstance } from '../../src/apis/liv-backend-http-client';



  /**
 * @summary Get Checkout Session
 */
export const getCheckoutSessionV1StripeCheckoutSessionSessionIdGet = (
    sessionId: string,
 ) => {
      return customInstance<GetCheckoutSessionV1StripeCheckoutSessionSessionIdGet200>(
      {url: `/v1/stripe/checkout-session/${sessionId}`, method: 'GET'
    },
      );
    }
  /**
 * @summary Create Checkout Session To Pay For Investigation
 */
export const createCheckoutSessionToPayForInvestigationV1StripeCreateCheckoutSessionInvestigationPost = (
    livStripeInvestigationCheckoutRequest: LivStripeInvestigationCheckoutRequest,
 ) => {
      return customInstance<string>(
      {url: `/v1/stripe/create-checkout-session/investigation`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: livStripeInvestigationCheckoutRequest
    },
      );
    }
  /**
 * @summary Create Checkout Session To Pay For Rent Operation
 */
export const createCheckoutSessionToPayForRentOperationV1StripeCreateCheckoutSessionRentOperationPost = (
    livStripeRentOperationCheckoutRequest: LivStripeRentOperationCheckoutRequest,
 ) => {
      return customInstance<string>(
      {url: `/v1/stripe/create-checkout-session/rent_operation`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: livStripeRentOperationCheckoutRequest
    },
      );
    }
  export type GetCheckoutSessionV1StripeCheckoutSessionSessionIdGetResult = NonNullable<Awaited<ReturnType<typeof getCheckoutSessionV1StripeCheckoutSessionSessionIdGet>>>
export type CreateCheckoutSessionToPayForInvestigationV1StripeCreateCheckoutSessionInvestigationPostResult = NonNullable<Awaited<ReturnType<typeof createCheckoutSessionToPayForInvestigationV1StripeCreateCheckoutSessionInvestigationPost>>>
export type CreateCheckoutSessionToPayForRentOperationV1StripeCreateCheckoutSessionRentOperationPostResult = NonNullable<Awaited<ReturnType<typeof createCheckoutSessionToPayForRentOperationV1StripeCreateCheckoutSessionRentOperationPost>>>
