/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * liv-backend
 * OpenAPI spec version: 1.0
 */
import type {
  GetInvestigationProcessesCreatedByMeV1InvestigationsCreatedByMeGetParams,
  GetInvestigationProcessesForMeAsInterestedPartyV1InvestigationsForMeAsInterestedPartyGetParams,
  GetInvestigationProcessesForMeAsInvestigationSubjectV1InvestigationsForMeAsInvestigationSubjectGetParams,
  GetInvestigationResultForSubjectV1InvestigationsInvestigationResultForSubjectUuidGet200,
  LivInvestigationMarkAsPaymentProcessingRequest,
  LivInvestigationPOCreateRequest,
  LivInvestigationPackagePOGet,
  LivInvestigationProcessInterestedPartyPOCreate,
  LivInvestigationProcessInterestedPartyPOGet,
  LivInvestigationProcessInterestedPartyPOUpdate,
  LivInvestigationProcessPOGetResponse,
  LivInvestigationProcessPOUpdatePackage,
  LivInvestigationProcessPOUpdatePayee,
  LivInvestigationProcessStatusPOGet,
  LivInvestigationSubjectInputsForOperationsPOUpdateRequest,
  LivInvestigationSubjectPOAddUserToInvestigate,
  LivInvestigationSubjectPOGetResponse,
  LivInvestigationSubjectPOUpdateUserToInvestigate,
  PaginatedResponseLivInvestigationProcessPOGetResponse
} from '../livBackend.schemas'
import { customInstance } from '../../src/apis/liv-backend-http-client';



  /**
 * @summary Get Investigation Process Statuses
 */
export const getInvestigationProcessStatusesV1InvestigationsStatusesGet = (
    
 ) => {
      return customInstance<LivInvestigationProcessStatusPOGet[]>(
      {url: `/v1/investigations/statuses`, method: 'GET'
    },
      );
    }
  /**
 * @summary Get Investigation Processes Created By Me
 */
export const getInvestigationProcessesCreatedByMeV1InvestigationsCreatedByMeGet = (
    params?: GetInvestigationProcessesCreatedByMeV1InvestigationsCreatedByMeGetParams,
 ) => {
      return customInstance<PaginatedResponseLivInvestigationProcessPOGetResponse>(
      {url: `/v1/investigations/created_by_me`, method: 'GET',
        params
    },
      );
    }
  /**
 * @summary Get Investigation Processes For Me As Investigation Subject
 */
export const getInvestigationProcessesForMeAsInvestigationSubjectV1InvestigationsForMeAsInvestigationSubjectGet = (
    params?: GetInvestigationProcessesForMeAsInvestigationSubjectV1InvestigationsForMeAsInvestigationSubjectGetParams,
 ) => {
      return customInstance<PaginatedResponseLivInvestigationProcessPOGetResponse>(
      {url: `/v1/investigations/for_me_as_investigation_subject`, method: 'GET',
        params
    },
      );
    }
  /**
 * @summary Get Packages Enabled
 */
export const getPackagesEnabledV1InvestigationsPackagesEnabledGet = (
    
 ) => {
      return customInstance<LivInvestigationPackagePOGet[]>(
      {url: `/v1/investigations/packages_enabled`, method: 'GET'
    },
      );
    }
  /**
 * @summary Get Package By Uuid
 */
export const getPackageByUuidV1InvestigationsPackagesUuidGet = (
    uuid: string,
 ) => {
      return customInstance<LivInvestigationPackagePOGet>(
      {url: `/v1/investigations/packages/${uuid}`, method: 'GET'
    },
      );
    }
  /**
 * @summary Get Investigation Processes For Me As Interested Party
 */
export const getInvestigationProcessesForMeAsInterestedPartyV1InvestigationsForMeAsInterestedPartyGet = (
    params?: GetInvestigationProcessesForMeAsInterestedPartyV1InvestigationsForMeAsInterestedPartyGetParams,
 ) => {
      return customInstance<PaginatedResponseLivInvestigationProcessPOGetResponse>(
      {url: `/v1/investigations/for_me_as_interested_party`, method: 'GET',
        params
    },
      );
    }
  /**
 * @summary Update Investigation Paying User
 */
export const updateInvestigationPayingUserV1InvestigationsUpdatePayingUserPatch = (
    livInvestigationProcessPOUpdatePayee: LivInvestigationProcessPOUpdatePayee,
 ) => {
      return customInstance<LivInvestigationProcessPOGetResponse>(
      {url: `/v1/investigations/update_paying_user`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: livInvestigationProcessPOUpdatePayee
    },
      );
    }
  /**
 * @summary Create New Interested Party For Invesgation Process
 */
export const createNewInterestedPartyForInvesgationProcessV1InvestigationsInterestedPartyPut = (
    livInvestigationProcessInterestedPartyPOCreate: LivInvestigationProcessInterestedPartyPOCreate,
 ) => {
      return customInstance<LivInvestigationProcessInterestedPartyPOGet>(
      {url: `/v1/investigations/interested_party`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: livInvestigationProcessInterestedPartyPOCreate
    },
      );
    }
  /**
 * @summary Update Interested Party For Invesgation Process
 */
export const updateInterestedPartyForInvesgationProcessV1InvestigationsInterestedPartyPatch = (
    livInvestigationProcessInterestedPartyPOUpdate: LivInvestigationProcessInterestedPartyPOUpdate,
 ) => {
      return customInstance<LivInvestigationProcessPOGetResponse>(
      {url: `/v1/investigations/interested_party`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: livInvestigationProcessInterestedPartyPOUpdate
    },
      );
    }
  /**
 * @summary Remove Interested Party From Investigation Process
 */
export const removeInterestedPartyFromInvestigationProcessV1InvestigationsInterestedPartyInterestedPartyUuidInvestigationProcessUuidDelete = (
    interestedPartyUuid: string,
    investigationProcessUuid: string,
 ) => {
      return customInstance<unknown>(
      {url: `/v1/investigations/interested_party/${interestedPartyUuid}/${investigationProcessUuid}`, method: 'DELETE'
    },
      );
    }
  /**
 * @summary Add Investigation Subject
 */
export const addInvestigationSubjectV1InvestigationsInvestigationSubjectPut = (
    livInvestigationSubjectPOAddUserToInvestigate: LivInvestigationSubjectPOAddUserToInvestigate,
 ) => {
      return customInstance<LivInvestigationProcessPOGetResponse>(
      {url: `/v1/investigations/investigation_subject`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: livInvestigationSubjectPOAddUserToInvestigate
    },
      );
    }
  /**
 * @summary Patch Investigation Subject
 */
export const patchInvestigationSubjectV1InvestigationsInvestigationSubjectPatch = (
    livInvestigationSubjectPOUpdateUserToInvestigate: LivInvestigationSubjectPOUpdateUserToInvestigate,
 ) => {
      return customInstance<LivInvestigationProcessPOGetResponse>(
      {url: `/v1/investigations/investigation_subject`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: livInvestigationSubjectPOUpdateUserToInvestigate
    },
      );
    }
  /**
 * @summary Delete Investigation Subject
 */
export const deleteInvestigationSubjectV1InvestigationsInvestigationSubjectInvestigationSubjectUuidInvestigationProcessUuidDelete = (
    investigationSubjectUuid: string,
    investigationProcessUuid: string,
 ) => {
      return customInstance<unknown>(
      {url: `/v1/investigations/investigation_subject/${investigationSubjectUuid}/${investigationProcessUuid}`, method: 'DELETE'
    },
      );
    }
  /**
 * @summary Assign Package To Investigation Process
 */
export const assignPackageToInvestigationProcessV1InvestigationsAssignPackageToInvestigationProcessPut = (
    livInvestigationProcessPOUpdatePackage: LivInvestigationProcessPOUpdatePackage,
 ) => {
      return customInstance<LivInvestigationProcessPOGetResponse>(
      {url: `/v1/investigations/assign_package_to_investigation_process`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: livInvestigationProcessPOUpdatePackage
    },
      );
    }
  /**
 * @summary Update Investigation Subject Input For Operations
 */
export const updateInvestigationSubjectInputForOperationsV1InvestigationsInvestigationSubjectInputsForOperationsPatch = (
    livInvestigationSubjectInputsForOperationsPOUpdateRequest: LivInvestigationSubjectInputsForOperationsPOUpdateRequest,
 ) => {
      return customInstance<LivInvestigationSubjectPOGetResponse>(
      {url: `/v1/investigations/investigation_subject_inputs_for_operations`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: livInvestigationSubjectInputsForOperationsPOUpdateRequest
    },
      );
    }
  /**
 * @summary Mark Investigation Process As Payment Processing
 */
export const markInvestigationProcessAsPaymentProcessingV1InvestigationsMarkAsPaymentProcessingPost = (
    livInvestigationMarkAsPaymentProcessingRequest: LivInvestigationMarkAsPaymentProcessingRequest,
 ) => {
      return customInstance<unknown>(
      {url: `/v1/investigations/mark_as_payment_processing`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: livInvestigationMarkAsPaymentProcessingRequest
    },
      );
    }
  /**
 * @summary Get Investigation Subject By Uuid
 */
export const getInvestigationSubjectByUuidV1InvestigationsInvestigationSubjectUuidGet = (
    uuid: string,
 ) => {
      return customInstance<LivInvestigationSubjectPOGetResponse>(
      {url: `/v1/investigations/investigation_subject/${uuid}`, method: 'GET'
    },
      );
    }
  /**
 * @summary Get Investigation Result For Subject
 */
export const getInvestigationResultForSubjectV1InvestigationsInvestigationResultForSubjectUuidGet = (
    uuid: string,
 ) => {
      return customInstance<GetInvestigationResultForSubjectV1InvestigationsInvestigationResultForSubjectUuidGet200>(
      {url: `/v1/investigations/investigation_result_for_subject/${uuid}`, method: 'GET'
    },
      );
    }
  /**
 * @summary Create New Investigation Process
 */
export const createNewInvestigationProcessV1InvestigationsPut = (
    livInvestigationPOCreateRequest: LivInvestigationPOCreateRequest,
 ) => {
      return customInstance<LivInvestigationProcessPOGetResponse>(
      {url: `/v1/investigations`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: livInvestigationPOCreateRequest
    },
      );
    }
  /**
 * @summary Get Investigation Process By Uuid
 */
export const getInvestigationProcessByUuidV1InvestigationsUuidGet = (
    uuid: string,
 ) => {
      return customInstance<LivInvestigationProcessPOGetResponse>(
      {url: `/v1/investigations/${uuid}`, method: 'GET'
    },
      );
    }
  export type GetInvestigationProcessStatusesV1InvestigationsStatusesGetResult = NonNullable<Awaited<ReturnType<typeof getInvestigationProcessStatusesV1InvestigationsStatusesGet>>>
export type GetInvestigationProcessesCreatedByMeV1InvestigationsCreatedByMeGetResult = NonNullable<Awaited<ReturnType<typeof getInvestigationProcessesCreatedByMeV1InvestigationsCreatedByMeGet>>>
export type GetInvestigationProcessesForMeAsInvestigationSubjectV1InvestigationsForMeAsInvestigationSubjectGetResult = NonNullable<Awaited<ReturnType<typeof getInvestigationProcessesForMeAsInvestigationSubjectV1InvestigationsForMeAsInvestigationSubjectGet>>>
export type GetPackagesEnabledV1InvestigationsPackagesEnabledGetResult = NonNullable<Awaited<ReturnType<typeof getPackagesEnabledV1InvestigationsPackagesEnabledGet>>>
export type GetPackageByUuidV1InvestigationsPackagesUuidGetResult = NonNullable<Awaited<ReturnType<typeof getPackageByUuidV1InvestigationsPackagesUuidGet>>>
export type GetInvestigationProcessesForMeAsInterestedPartyV1InvestigationsForMeAsInterestedPartyGetResult = NonNullable<Awaited<ReturnType<typeof getInvestigationProcessesForMeAsInterestedPartyV1InvestigationsForMeAsInterestedPartyGet>>>
export type UpdateInvestigationPayingUserV1InvestigationsUpdatePayingUserPatchResult = NonNullable<Awaited<ReturnType<typeof updateInvestigationPayingUserV1InvestigationsUpdatePayingUserPatch>>>
export type CreateNewInterestedPartyForInvesgationProcessV1InvestigationsInterestedPartyPutResult = NonNullable<Awaited<ReturnType<typeof createNewInterestedPartyForInvesgationProcessV1InvestigationsInterestedPartyPut>>>
export type UpdateInterestedPartyForInvesgationProcessV1InvestigationsInterestedPartyPatchResult = NonNullable<Awaited<ReturnType<typeof updateInterestedPartyForInvesgationProcessV1InvestigationsInterestedPartyPatch>>>
export type RemoveInterestedPartyFromInvestigationProcessV1InvestigationsInterestedPartyInterestedPartyUuidInvestigationProcessUuidDeleteResult = NonNullable<Awaited<ReturnType<typeof removeInterestedPartyFromInvestigationProcessV1InvestigationsInterestedPartyInterestedPartyUuidInvestigationProcessUuidDelete>>>
export type AddInvestigationSubjectV1InvestigationsInvestigationSubjectPutResult = NonNullable<Awaited<ReturnType<typeof addInvestigationSubjectV1InvestigationsInvestigationSubjectPut>>>
export type PatchInvestigationSubjectV1InvestigationsInvestigationSubjectPatchResult = NonNullable<Awaited<ReturnType<typeof patchInvestigationSubjectV1InvestigationsInvestigationSubjectPatch>>>
export type DeleteInvestigationSubjectV1InvestigationsInvestigationSubjectInvestigationSubjectUuidInvestigationProcessUuidDeleteResult = NonNullable<Awaited<ReturnType<typeof deleteInvestigationSubjectV1InvestigationsInvestigationSubjectInvestigationSubjectUuidInvestigationProcessUuidDelete>>>
export type AssignPackageToInvestigationProcessV1InvestigationsAssignPackageToInvestigationProcessPutResult = NonNullable<Awaited<ReturnType<typeof assignPackageToInvestigationProcessV1InvestigationsAssignPackageToInvestigationProcessPut>>>
export type UpdateInvestigationSubjectInputForOperationsV1InvestigationsInvestigationSubjectInputsForOperationsPatchResult = NonNullable<Awaited<ReturnType<typeof updateInvestigationSubjectInputForOperationsV1InvestigationsInvestigationSubjectInputsForOperationsPatch>>>
export type MarkInvestigationProcessAsPaymentProcessingV1InvestigationsMarkAsPaymentProcessingPostResult = NonNullable<Awaited<ReturnType<typeof markInvestigationProcessAsPaymentProcessingV1InvestigationsMarkAsPaymentProcessingPost>>>
export type GetInvestigationSubjectByUuidV1InvestigationsInvestigationSubjectUuidGetResult = NonNullable<Awaited<ReturnType<typeof getInvestigationSubjectByUuidV1InvestigationsInvestigationSubjectUuidGet>>>
export type GetInvestigationResultForSubjectV1InvestigationsInvestigationResultForSubjectUuidGetResult = NonNullable<Awaited<ReturnType<typeof getInvestigationResultForSubjectV1InvestigationsInvestigationResultForSubjectUuidGet>>>
export type CreateNewInvestigationProcessV1InvestigationsPutResult = NonNullable<Awaited<ReturnType<typeof createNewInvestigationProcessV1InvestigationsPut>>>
export type GetInvestigationProcessByUuidV1InvestigationsUuidGetResult = NonNullable<Awaited<ReturnType<typeof getInvestigationProcessByUuidV1InvestigationsUuidGet>>>
